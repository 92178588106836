<script lang="ts">
import ApocSearch from '@/components/common/ApocSearch.vue';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

// content 페이지의 상단 배너
export default defineComponent({
	name: 'ContentBanner',
	components: { ApocSearch },
	props: {
		onEnter: {
			type: Function as PropType<(result: string) => void>,
			required: false,
			default: () => ({}),
		},
		modelValue: {
			type: String as PropType<string>,
		},
	},
	setup(props) {
		const { t } = useI18n({ useScope: 'global' });

		return {
			t,
		};
	},
});
</script>

<template>
	<div class="content-banner">
		<div class="content-banner-container grid-expansion">
			<div class="banner-finger-img-container">
				<div class="banner-finger-img">
					<object data="/assets/images/common/icons/banner-finger-bg.svg" />
				</div>
			</div>
			<div class="banner-text-input">
				<div class="main-text">{{ t('layout.contentBanner.mainText') }}</div>
				<div class="sub-text-wrapper">
					<div class="sub-text">{{ t('layout.contentBanner.subText') }}</div>
				</div>
				<apoc-search :model-value="modelValue" @onEnter="onEnter" />
			</div>
			<div class="banner-like-img-container">
				<div class="banner-like-img">
					<object data="/assets/images/common/icons/banner-like.svg" />
				</div>
			</div>
		</div>
	</div>
</template>
