<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'MoreArrow',
	components: { ApocImageSet },
	props: {
		onClick: {
			type: Function as PropType<() => void>,
			default: () => {},
		},
	},
	setup() {
		const { t } = useI18n({ useScope: 'global' });

		return {
			t,
		};
	},
});
</script>

<template>
	<div class="more-arrow" @click="onClick">
		<div>{{ t('layout.listItem.more') }}</div>
		<div><apoc-image-set src="/assets/images/common/icons/arrow-right.svg" /></div>
	</div>
</template>

<style scoped></style>
