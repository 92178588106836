<script lang="ts">
import { getContentDetail, getContentListIncludedCategory } from '@/api/content';
import { CategoryConnectRecEntityDto } from '@/api/dto/category.dto';
import { ContentEntityDto, SearchContentListDto } from '@/api/dto/content.dto';
import MainHeader from '@/components/header/MainHeader.vue';
import MainContentsList from '@/components/layout/MainContentsList.vue';
import ContentBanner from '@/components/layout/common/ContentBanner.vue';
import MoreArrow from '@/components/layout/common/MoreArrow.vue';
import AppConfig, { APP_ENV_TYPE } from '@/constants';
import { HEADER_TYPE, POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { CATEGORY_TYPE_CODE, LOADING_TYPE, OPEN_TYPE_CODE, ORDER_TYPE, PARENT_CATEGORY_TYPE_CODE } from '@/types';
import { getApiClient } from '@/utils/apiClient';
import { formatNumberWithCommas, getQueryString } from '@/utils/common-util';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { loadApiGauge } from '@/utils/utils';
import { getEmitter } from '@/utils/event-emitter-utils';

export default defineComponent({
	name: 'Main',
	components: { MainContentsList, MoreArrow, ContentBanner, MainHeader },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const isMore = ref<boolean>(false);
		const categoryContents = ref<CategoryConnectRecEntityDto[]>([]);
		const popularContents = ref<CategoryConnectRecEntityDto[]>([]);
		const bestContents = ref<CategoryConnectRecEntityDto[]>([]);
		const newestContents = ref<CategoryConnectRecEntityDto[]>([]);
		const isNewestContentsLoading = ref<boolean>(false);
		const isPopularContentsLoading = ref<boolean>(false);
		const isBestContentsLoading = ref<boolean>(false);
		const isCategoryDataLoading = ref<boolean>(false);
		const router = useRouter();
		const storeManager = initStore();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const totalSearchContentsCount = ref<number>(0);
		const compPageNo = ref<number>(1);
		const compRowCount = ref(AppConfig.KEYS.MORE_LIMIT.CONTENTS_LIST);
		const keyword = ref<string | undefined>(getQueryString('keyword') ? getQueryString('keyword') : '');
		const isContentHeaderVisible = ref<boolean>(true);
		const headerElement = ref<HTMLElement | null>(null);
		const lateLoadTimer = ref<ReturnType<typeof setTimeout>>(); // api load가 늦은 경우 작동하는 타이머

		/**
		 * PopularContents
		 * CES 대비 STAGE에서는 고정된 콘텐츠가 노출되게 끔 작업
		 * */
		const getPopularBestContents = () => {
			if (AppConfig.ENV !== APP_ENV_TYPE.DEV) {
				const wellMadeContent = [
					'a80f768c-df5b-433e-a536-2ccef0769c1c',
					'965f9425-a1c4-40a0-badb-24778f473082',
					'5ba7d944-f482-4c25-99c4-2b881000f444',
					'8a6408a0-34e1-43d1-b158-f57c12079de7',
				];
				let count = 0;
				for (const idx of wellMadeContent) {
					const param = new ContentEntityDto();
					param.contentIdx = idx as string;
					storeManager.dataStore.addPageApiTotalCount(1);
					getContentDetail(apiClient, param)
						.then(async res => {
							storeManager.dataStore.addLoadedCount(1);
							// console.log('getContentDetail: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
							loadApiGauge(LOADING_TYPE.ROLL, '', '');
							if (res.resultCode !== 0) {
								console.error(res);
								window.alert(t('msg.RESULT_INVALID_PARAM'));
							} else {
								if (res.data) {
									count++;
									const content = res.data;
									const data = new CategoryConnectRecEntityDto();
									data.contentIdx = content.contentIdx;
									data.contentName = content.contentName;
									if (content.userNicknm) data.userNicknm = content.userNicknm;
									if (content.contentMainImg) data.contentMainImg = content.contentMainImg;
									bestContents.value.push(data);
									if (wellMadeContent.length === count) {
										isBestContentsLoading.value = true;
									}
								}
							}
						})
						.catch(() => {
							storeManager.dataStore.addLoadedCount(1);
							// console.error('getContentDetail: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
							loadApiGauge(LOADING_TYPE.ROLL, '', '');
						});
				}
			} else {
				const searchParam = new SearchContentListDto();
				searchParam.orderType = ORDER_TYPE.POPULAR;
				searchParam.page = 1;
				searchParam.rows = 10;
				searchParam.openTypeCode = OPEN_TYPE_CODE.PUBLIC;
				storeManager.dataStore.addPageApiTotalCount(1);
				getContentListIncludedCategory(apiClient, searchParam)
					.then(res => {
						storeManager.dataStore.addLoadedCount(1);
						// console.log('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
						loadApiGauge(LOADING_TYPE.ROLL, '', '');
						if (res.resultCode === 0) {
							if (res.data) bestContents.value = res.data;
							bestContents.value = bestContents.value.reverse();
							isBestContentsLoading.value = true;
						}
					})
					.catch(() => {
						isBestContentsLoading.value = true;
						storeManager.dataStore.addLoadedCount(1);
						// console.error('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
						loadApiGauge(LOADING_TYPE.ROLL, '', '');
					});
			}
		};

		// 인기 콘텐츠 조회
		const getPopularContents = () => {
			const searchParam = new SearchContentListDto();
			searchParam.orderType = ORDER_TYPE.POPULAR;
			searchParam.likeCategoryCode = PARENT_CATEGORY_TYPE_CODE.APC;
			searchParam.page = 1;
			searchParam.openTypeCode = OPEN_TYPE_CODE.PUBLIC;
			searchParam.categoryTypeCode = CATEGORY_TYPE_CODE.CONTENT;
			//searchParam.notTemplate = true;
			//searchParam.regrUserIdx = route.params.id as string;
			//searchParam.onlyMine = true;
			//searchParam.openTypeCode = itsMe.value ? undefined : OPEN_TYPE_CODE.PUBLIC; // 공개여부 체크 (공개된 콘텐츠만 노출)
			searchParam.openTypeCode = OPEN_TYPE_CODE.PUBLIC;
			storeManager.dataStore.addPageApiTotalCount(1);
			getContentListIncludedCategory(apiClient, searchParam)
				.then(res => {
					storeManager.dataStore.addLoadedCount(1);
					// console.log('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					if (res.resultCode === 0) {
						if (res.data) {
							popularContents.value = res.data;
							popularContents.value = popularContents.value.reverse();
							// for (const content of res.data){
							//   popularContents.value.push(content);
							// }
						}
						isPopularContentsLoading.value = true;
					}
				})
				.catch(() => {
					storeManager.dataStore.addLoadedCount(1);
					// console.error('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
				});
		};

		// 최신 콘텐츠 조회
		const getNewestContents = () => {
			const searchParam = new SearchContentListDto();
			searchParam.orderType = ORDER_TYPE.NEW;
			searchParam.page = 1;
			searchParam.rows = 10;
			searchParam.openTypeCode = OPEN_TYPE_CODE.PUBLIC;
			//searchParam.likeCategoryCode = PARENT_CATEGORY_TYPE_CODE.APC;
			//searchParam.categoryTypeCode = CATEGORY_TYPE_CODE.CONTENT;
			//searchParam.openYn = STATE_YN.Y;
			storeManager.dataStore.addPageApiTotalCount(1);
			getContentListIncludedCategory(apiClient, searchParam)
				.then(res => {
					storeManager.dataStore.addLoadedCount(1);
					// console.log('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					if (res.resultCode === 0) {
						if (res.data) newestContents.value = res.data;
						newestContents.value = newestContents.value.reverse();
						isNewestContentsLoading.value = true;
					}
				})
				.catch(() => {
					isNewestContentsLoading.value = true;
					storeManager.dataStore.addLoadedCount(1);
					// console.error('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
				});
		};

		const getWellMadeContentList = () => {
			if (AppConfig.ENV !== APP_ENV_TYPE.DEV) {
				const wellMadeContent = [
					'274eeef5-7fbb-4c4e-9601-9809ea2e12e4',
					'a15b0026-1a07-42c5-ba29-921ef9bc6e09',
					'371857ba-aa12-44bf-a2dd-5dbdff3ef06e',
					'738fde65-2fea-4606-a98e-e03efea9fe24',
				];
				let count = 0;
				for (const idx of wellMadeContent) {
					const param = new ContentEntityDto();
					param.contentIdx = idx as string;
					storeManager.dataStore.addPageApiTotalCount(1);
					getContentDetail(apiClient, param)
						.then(async res => {
							storeManager.dataStore.addLoadedCount(1);
							// console.log('getContentDetail: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
							loadApiGauge(LOADING_TYPE.ROLL, '', '');
							if (res.resultCode !== 0) {
								console.error(res);
								window.alert(t('msg.RESULT_INVALID_PARAM'));
								window.close();
							} else {
								if (res.data) {
									count++;
									const content = res.data;
									const data = new CategoryConnectRecEntityDto();
									data.contentIdx = content.contentIdx;
									data.contentName = content.contentName;
									if (content.userNicknm) data.userNicknm = content.userNicknm;
									if (content.contentMainImg) data.contentMainImg = content.contentMainImg;
									categoryContents.value.push(data);
									if (wellMadeContent.length === count) {
										//   getCategoryAssetList();
										isCategoryDataLoading.value = true;
									}
								}
							}
						})
						.catch(() => {
							storeManager.dataStore.addLoadedCount(1);
							// console.error('getContentDetail: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
							loadApiGauge(LOADING_TYPE.ROLL, '', '');
						});
				}
			} else {
				const searchParam = new SearchContentListDto();
				searchParam.orderType = ORDER_TYPE.LIKE_COUNT_DESC;
				searchParam.page = 1;
				searchParam.rows = 10;
				searchParam.openTypeCode = OPEN_TYPE_CODE.PUBLIC;
				storeManager.dataStore.addPageApiTotalCount(1);
				getContentListIncludedCategory(apiClient, searchParam)
					.then(res => {
						storeManager.dataStore.addLoadedCount(1);
						// console.log('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
						loadApiGauge(LOADING_TYPE.ROLL, '', '');
						if (res.resultCode === 0) {
							if (res.data) categoryContents.value = res.data;
							categoryContents.value = categoryContents.value.reverse();
							isCategoryDataLoading.value = true;
						}
					})
					.catch(() => {
						isCategoryDataLoading.value = true;
						storeManager.dataStore.addLoadedCount(1);
						// console.error('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
						loadApiGauge(LOADING_TYPE.ROLL, '', '');
					});
			}
		};

		// 검색 결과 & 콘텐츠 리스트 조회
		const getCategoryAssetList = () => {
			const searchParam = new SearchContentListDto();
			searchParam.page = compPageNo.value;
			searchParam.rows = compRowCount.value;
			searchParam.openTypeCode = OPEN_TYPE_CODE.PUBLIC;
			//searchParam.likeCategoryCode = PARENT_CATEGORY_TYPE_CODE.APC;
			//searchParam.categoryTypeCode = CATEGORY_TYPE_CODE.CONTENT;
			searchParam.orderType = ORDER_TYPE.NEW;
			storeManager.dataStore.addPageApiTotalCount(1);
			getContentListIncludedCategory(apiClient, searchParam)
				.then(res => {
					storeManager.dataStore.addLoadedCount(1);
					// console.log('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					if (res.resultCode === 0) {
						if (res.data) {
							isMore.value = res.totalCount > AppConfig.KEYS.PAGING_ROW;
							// for (const content of res.data){
							//   categoryContents.value.push(content);
							// }
							// categoryContents.value = res.data;
						}
						isCategoryDataLoading.value = true;
					}
				})
				.catch(() => {
					storeManager.dataStore.addLoadedCount(1);
					// console.error('getContentListIncludedCategory: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
				});
		};

		// 모든 콘텐츠 다시 불러오기
		const resetData = () => {
			getPopularContents();
			getPopularBestContents();
			getNewestContents();
			getWellMadeContentList();
		};

		// 더보기 설정
		const seeMore = (orderType: string) => {
			storeManager.dataStore.setOrderType(orderType);
			router.push({ path: '/search', query: { keyword: keyword.value, page: 1 } });
		};

		const handleScroll = () => {
			const scrollPosition = window.scrollY || document.documentElement.scrollTop;
			if (isContentHeaderVisible.value) {
				storeManager.stateStore.setHeaderVisible(true);
			} else {
				storeManager.stateStore.setHeaderVisible(false);
			}

			if (window.innerWidth <= 767) {
				//모바일
				isContentHeaderVisible.value = scrollPosition <= 0;
			} else if (window.innerWidth <= 1023) {
				//태블릿
				isContentHeaderVisible.value = scrollPosition <= 0;
			} else {
				// 컴퓨터
				isContentHeaderVisible.value = scrollPosition <= 0;
			}
			setThemeColor();
		};

		const scrollIntoView = () => {
			if (headerElement.value) {
				headerElement.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		};

		// 반응형으로 요소의 높이가 변경되는 경우, resize 이벤트를 감지하여 scrollIntoView() 호출
		const handleResize = () => {
			scrollIntoView();
		};

		/**
		 * 테마 색깔 설정
		 *
		 * */
		const setThemeColor = () => {
			const theme = document.getElementById('theme-color') as HTMLMetaElement;
			if (theme) {
				if (isContentHeaderVisible.value) {
					theme.content = '#8976FD';
				} else {
					theme.content = '#FFFFFF';
				}
			}
		};

		/**
		 * 페이지를 벗어나는 경우 테마를 초기 값으로 재설정
		 * */
		const initThemeColor = () => {
			const theme = document.getElementById('theme-color') as HTMLMetaElement;
			if (theme) theme.content = '#FFFFFF';
		};

		/**
		 * 로딩바 활성화를 위한 타이머를 생성
		 */
		const initLateLoadTimer = () => {
			lateLoadTimer.value = setTimeout(() => {
				if (storeManager.dataStore.loadedCount < storeManager.dataStore.pageApiTotalCount) {
					// 현재 로드 되어야 하는 전체 API 개수 초기화 (전체 - 현재 로드된 갯수)
					const nowApiCount = storeManager.dataStore.pageApiTotalCount - storeManager.dataStore.loadedCount;
					storeManager.dataStore.setPageApiTotalCount(nowApiCount);
					// 현재 로드된 API 갯수 초기화
					storeManager.dataStore.setLoadedCount(0);
					storeManager.stateStore.loadingState = true;
				}
			}, 3000);
		};

		onMounted(() => {
			getEmitter().emit('LOADING_STATE', {
				loadingType: LOADING_TYPE.NONE,
				des: '',
				title: '',
				progress: 0,
			});
			storeManager.stateStore.setPopupMode(POPUP_MODE.INSPECTION_MODAL);
			storeManager.stateStore.setHeaderState(HEADER_TYPE.DEFAULT); //헤더 상태값 변경
			storeManager.dataStore.setHeaderTitle(''); //헤더 타이틀 빈 문자열 생성
			initLateLoadTimer();
			resetData();
			window.addEventListener('scroll', handleScroll);
			window.addEventListener('resize', handleResize);
			storeManager.dataStore.setSearchKeyword('');
			setThemeColor();
		});

		onUnmounted(() => {
			if (lateLoadTimer.value) clearTimeout(lateLoadTimer.value);
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleResize);
			initThemeColor();
		});

		return {
			isContentHeaderVisible,
			popularContents,
			bestContents,
			newestContents,
			categoryContents,
			isMore,
			totalSearchContentsCount,
			isNewestContentsLoading,
			isPopularContentsLoading,
			isCategoryDataLoading,
			isBestContentsLoading,
			keyword,
			ORDER_TYPE,
			t,
			seeMore,
			resetData,
			formatNumberWithCommas,
			getWellMadeContentList,
			getCategoryAssetList,
			getPopularContents,
			getPopularBestContents,
			getNewestContents,
		};
	},
});
</script>

<template>
	<ClientOnly>
		<main-header ref="headerElement" :is-content-header-visible="isContentHeaderVisible" :model-value="keyword" />
		<content-banner :model-value="keyword" />
		<div class="main-wrapper">
			<div class="main">
				<div class="title-wrapper">
					<i18n-t keypath="layout.listItem.content" tag="h2" scope="global">
						<template #contentTitle>
							<strong>{{ t('layout.listItem.well') }}</strong>
						</template>
					</i18n-t>
					<!--					<more-arrow :on-click="() => seeMore(ORDER_TYPE.NEW)" />-->
				</div>
				<main-contents-list :is-loaded="isCategoryDataLoading" :data="categoryContents.slice(0, 4)" :get-contents-list="getWellMadeContentList" />
				<div class="title-wrapper">
					<i18n-t keypath="layout.listItem.content" tag="h2" scope="global">
						<template #contentTitle>
							<strong>{{ t('layout.listItem.popular') }}</strong>
						</template>
					</i18n-t>
					<more-arrow :on-click="() => seeMore(ORDER_TYPE.POPULAR)" />
				</div>
				<main-contents-list :is-loaded="isPopularContentsLoading" :data="popularContents.slice(0, 4)" :get-contents-list="getPopularContents" />
				<!--				<contents-list-->
				<!--					id="no-list-info"-->
				<!--					class="content-wide"-->
				<!--					:reset-data="resetData"-->
				<!--					:data="categoryContents"-->
				<!--					:is-data-loading="isCategoryDataLoading" />-->
				<div class="title-wrapper">
					<i18n-t keypath="layout.listItem.content" tag="h2" scope="global">
						<template #contentTitle>
							<strong>{{ t('layout.listItem.newest') }}</strong>
						</template>
					</i18n-t>
					<more-arrow :on-click="() => seeMore(ORDER_TYPE.NEW)" />
				</div>
				<main-contents-list :is-loaded="isNewestContentsLoading" :data="newestContents.slice(0, 4)" :get-contents-list="getNewestContents" />
				<!--				<contents-list-->
				<!--					id="other-ppl-ex"-->
				<!--					class="content-wide"-->
				<!--					:data="categoryContents.slice(0, 3)"-->
				<!--					:reset-data="resetData"-->
				<!--					:is-data-loading="isCategoryDataLoading">-->
				<!--					<span>{{ t('layout.listItem.other') }}</span>-->
				<!--					<strong>{{ t('layout.listItem.apoc') }}</strong>-->
				<!--					<span>{{ t('layout.listItem.otherText') }}</span>-->
				<!--				</contents-list>-->
				<div class="title-wrapper">
					<i18n-t keypath="layout.listItem.content" tag="h2" scope="global">
						<template #contentTitle>
							<strong>{{ t('layout.listItem.recommend') }}</strong>
						</template>
					</i18n-t>
					<!--					<more-arrow :on-click="() => seeMore(ORDER_TYPE.POPULAR)" />-->
				</div>
				<main-contents-list :is-loaded="isBestContentsLoading" :data="bestContents.slice(0, 4)" :get-contents-list="getPopularBestContents" />
				<!--				<contents-list class="content-wide" :data="categoryContents.slice(0, 3)" :reset-data="resetData" :is-data-loading="isCategoryDataLoading">-->
				<!--					<strong>{{ t('layout.listItem.smart') }}</strong>-->
				<!--					<span>{{ t('layout.listItem.smartText') }}</span>-->
				<!--				</contents-list>-->
				<!--				<contents-list class="content-wide" :data="categoryContents.slice(3, 6)" :reset-data="resetData" :is-data-loading="isCategoryDataLoading">-->
				<!--				</contents-list>-->
			</div>
		</div>
	</ClientOnly>
</template>
